import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import TarifsCards from "../components/TarifsCards";
import SEO from "../components/seo";
const Tarifs = () => {
  return (
    <Layout>
      <SEO title="MVDev Développeur Web Freelance le Pradet, Création de site vitrine E-commerce, application | Les Tarifs" />
      <TarifsWrapper>
        <h2>Nos Tarifs</h2>
        <CardsWrapper>
          <TarifsCards />
        </CardsWrapper>
      </TarifsWrapper>
    </Layout>
  );
};

const TarifsWrapper = styled.div`
  background-color: var(--textWhite);
  width: 100%;
  min-height: 100vh;
  font-family: "Lato", sans-serif;
  color: var(--textBlack);
  padding: 3rem;
  h2 {
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
    padding: 1rem;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
export default Tarifs;
