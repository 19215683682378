import React from "react";
import styled from "styled-components";

const TarifsCards = () => {
  return (
    <CardWrapper>
      <div className="Card">
        <h4>L’ESSENTIEL POUR DÉMARRER RAPIDEMENT SON ACTIVITÉ !</h4>
        <h3>PACK « START KIT »</h3>
        <h3>Contenu du Start Kit</h3>
        <p>
          Le « START KIT » inclus l’essentiel pour démarrer rapidement et à
          moindre coût votre activité: un logo et les outils de communication
          essentiels pour votre entreprise nouvellement créé.
        </p>
        <h3>01 – LOGO</h3>
        <ul>
          <li>
            Réunion téléphonique ou physique de définition du projet: activité,
            valeurs de l’entreprise, personnalité, influences, souhaits
          </li>
          <li>Création de 3 pistes graphiques</li>
          <li>
            Optimisations de la piste retenue avec 2 retouches suivant vos
            retours
          </li>
          <li>
            Réalisation de plusieurs déclinaisons afin que vous puissiez être
            autonome dans son exploitation quel que soit le support.
          </li>
          <li>
            Mise en forme et livraison du logo et de ses variantes en format HD
            vectoriel
          </li>
        </ul>
        <h3>02 – OUTILS DE COMMUNICATION ESSENTIELS</h3>
        <ul>
          <li>Design de modèles de cartes de visites (2 propositions)</li>
          <li>
            Réalisation d’une page web « one-page » incluant la présentation
            succincte de votre entreprise, vos coordonnées et un formulaire de
            contact (Site responsive adapté tous supports)
          </li>
          <li>Mise en place du contenu, de votre textes et vos images</li>
          <li>Hébergement de votre page web</li>
          <li>1 nom de domaine inclus </li>
          <li>Création de 1 adresses emails incluses </li>
          <li>Liens de partage sur les réseaux sociaux</li>
          <li>Optimisation du référencement</li>
        </ul>
        <h3>BUDGET: 650 € *</h3>
        <p>* Prix à titre informatif : Le tarifs final sera donné par devis</p>
        <p>
          Vous êtes à la fin du projet, propriétaire de l'ensemble des sources
          (fichiers) composant celui-ci.
        </p>
      </div>
      <div className="Card">
        <h4>
          VOUS LIVRER UN SITE VITRINE « CLÉ EN MAIN » & VOUS RENDRE AUTONOME
          POUR SON EXPLOITATION.
        </h4>
        <h3>« PACK SITE VITRINE »</h3>
        <h3>Contenu du pack Site Vitrine</h3>
        <p>
          Le pack Site Vitrine intègre l’essentiel pour présenter votre
          entreprise et votre offre sur internet. Pour vous livrer un site web
          rapidement et à un coût maitrisé, nous nous basons pour le pack sur
          des modèles de sites optimisés que nous maitrisons, et que nous
          adapterons aux couleurs de votre entreprise et aux contenus que vous
          nous fournirez. Bien évidement, les modèles de sites sélectionnés sont
          compatibles « responsive design » afin d’être consulté sur smartphone,
          tablette et ordinateur.
        </p>
        <h3>Détail de la prestation:</h3>
        <ul>
          <li>
            Réunion téléphonique en visioconférence pour la définition du projet
          </li>
          <li>
            Recherches de modèles (fonctionnels & design) répondants à vos
            besoins & critères
          </li>
          <li>Proposition de 2 modèles de site optimisés</li>
          <li>
            Adaptation du thème sélectionné à votre image (logo et/ou charte
            graphique à nous fournir).
          </li>
          <li>Création de l’arborescence des pages type.</li>
          <li>Conseils pour la préparation de vos contenus.</li>
          <li>
            Ajout de vos contenus sur les pages type: Page d’accueil, Qui sommes
            nous ?, Contact, page de listing (d’offres, de services, de
            produits…), page de description d’une offre/service/produit
            (contenus à nous livrer suivant les modèles fournis: un fichier pour
            chaque page + images/photos d’illustrations à nous faire parvenir en
            haute définition).
          </li>
          <li>
            Mise en forme et ajout de la fonctionnalité de « Blog / Actualités »
          </li>
          <li>
            Mise en place d’un formulaire de contact avec consentement
            d’utilisation des données personnelles saisies.
          </li>
          <li>Mise en place de plugins d’acceptation « cookies & RGPD »</li>
          <li>Mise en place de Google Analytics </li>
          <li>
            Formation à distance à l’outil de gestion afin que vous puissiez
            être autonome pour la modification des contenus de vos pages et
            ajouter des nouvelles pages suivant les modèles réalisés. (La
            formation en visioconférence sera enregistrée et vous sera remise.)
          </li>
          <li>
            Hébergement & gestion sur un serveur web haute performance + nom de
            domaine inclus pour 5 ans
          </li>
        </ul>

        <h3>BUDGET: 999 € *</h3>
        <h3>
          DÉLAIS DE RÉALISATION: 2 semaines à 1 mois à réception de vos
          contenus.
        </h3>
        <p>* Prix à titre informatif : Le tarifs final sera donné par devis</p>
        <p>
          Vous êtes à la fin du projet, propriétaire de l'ensemble des sources
          (fichiers) composant celui-ci.
        </p>
      </div>
      <div className="Card">
        <h4>
          VOUS LIVRER UN SITE ECOMMERCE « CLÉ EN MAIN » & VOUS RENDRE AUTONOME
          POUR SON EXPLOITATION.
        </h4>
        <h3>« PACK SITE ECOMMERCE »</h3>
        <h3>Contenu du Pack E-commerce</h3>
        <p>L'essentiel pour vendre en ligne vos produits ou vos services.</p>
        <h3>Le « pack site eCommerce » dans le détail:</h3>
        <ul>
          <li>Réunion en visioconférence pour la définition du projet</li>
          <li>
            Recherches de modèles (fonctionnels & design) répondants à vos
            besoins & critères
          </li>
          <li>
            Proposition de 2 modèles de site optimisés (responsive, code de
            développement propre et ouvert)
          </li>
          <li>
            Adaptation du thème sélectionné à votre image (logo et/ou charte
            graphique à nous fournir).
          </li>
          <li>Création de l’arborescence des pages type.</li>
          <li>
            Ajout de vos contenus sur les pages type: Page d’accueil, Qui sommes
            nous ?, Contact, page de listing d’offres, page de description d’une
            offre (contenus à nous livrer suivant les modèles fournis: un
            fichier pour chaque page + images/photos d’illustrations à nous
            faire parvenir en haute définition).
          </li>
          <li>
            Configuration des information de la page produit, des différentes «
            catégories de produits » et de l’espace client.
          </li>
          <li>
            Configuration des emails types automatiques que recevra le client
            lors du processus d’achat (création de compte, confirmation de
            commande etc…)
          </li>
          <li>
            Importation de vos produits dans le système (contenus à nous livrer
            dans le fichier formaté fourni, sans modification du format)
          </li>
          <li>
            Configuration de la solution de paiement en ligne (Paygreen,
            solution française, commissions négociées pour vous)
          </li>
          <ul className="indentation">
            <li>Paiement CB Comptant</li>
            <li>Paiement CB en plusieurs fois</li>
            <li>Paiement par abonnement</li>
          </ul>
          <li>Configuration du module de livraison</li>
          <ul>
            <li>Retrait en magasin</li>
            <li>
              Livraison à domicile (avec comparateur de prix suivant les
              transporteurs)
            </li>
          </ul>
          <li>Configuration du module de Codes promotionnels.</li>
          <li>
            Mise en place d’un formulaire de contact avec consentement
            d’utilisation des données personnelles pour la conformité avec la
            règlementation RGPD.
          </li>
          <li>
            Mise en place de Google Analytics et déclaration auprès de Google
            pour accélérer son référencement.
          </li>
          <li>
            Formation à distance à l’outil de gestion afin que vous puissiez
            être autonome pour modifier le contenu de vos pages & gérer vos
            produits et vos ventes. (la formation en visio est enregistrée puis
            l’enregistrement vous est fourni.)
          </li>
          <li>
            Hébergement & gestion sur un serveur web sécurisé haute performance
            en France + Votre nom de domaine inclus.(offert la première année,
            150€ht/an ensuite)
          </li>
        </ul>
        <h3>BUDGET: 2500 € *</h3>
        <h3>DÉLAIS DE RÉALISATION: 2 à 6 semaines</h3>
        <p>* Prix à titre informatif : Le tarifs final sera donné par devis</p>
        <p>
          Vous êtes à la fin du projet, propriétaire de l'ensemble des sources
          (fichiers) composant celui-ci.
        </p>
      </div>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  background-color: var(--textWhite);
  width: 100%;
  min-height: 50vh;
  font-family: "Lato", sans-serif;
  color: var(--textBlack);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .Card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 2rem 0;
    border-radius: 20px;
    padding: 3rem;
    h2 {
      text-align: center;
    }
    h3 {
      font-size: 1.5rem;
      padding: 1rem;
    }

    h5 {
      font-size: 1.8rem;
      padding: 0.8rem;
    }
    p {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
    li {
      list-style: circle;
      padding: 0.2rem 0;
    }
    .indentation {
      margin: 0 2rem;
    }
  }
  @media screen and (max-width: 479px) {
    .Card {
      padding: 1.5rem;
      h2 {
        font-size: 1rem;
      }
      h3 {
        font-size: 1rem;
        padding: 1rem;
      }

      h5 {
        font-size: 0.8rem;
        padding: 0.8rem;
      }
      li {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }
`;

export default TarifsCards;
